import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
//import { VueAxios } from './axios'
//import { notification } from 'ant-design-vue'
import { authorizationValue } from '@/utils/settings'
import { getToken, getRefreshToken, getExpireTime } from '@/utils/auth'
import { ACCESS_TOKEN,EXPIRE_TIME,REFRESH_TOKEN } from '@/store/mutation-types'
// 请求超时时间，10s
const requestTimeOut = 10*1000
const success = 200
// 更换令牌的时间区间
const checkRegion = 30 * 60 * 1000
// 提示信息显示时长
const messageDuration = 5 * 1000
//const baseURL = process.env.VUE_APP_API_BASE_URL
const baseURL = '/api'

// 系统全局请求对象  创建 axios 实例
const service = axios.create({
  baseURL: baseURL,

  timeout: requestTimeOut,
  responseType: 'json',
  validateStatus(status) {
    return status === success
  }
})
const faxios = (URL, params, config = {}) => {
  return axios({
    url: baseURL+'/ilearn/mongo/upload',
    method: 'post',
    data: {
      ...params
    },
    headers: {
      'Authorization': 'bearer' + getToken(),
      'Content-Type': 'multipart/form-data'
    },
    ...config
  })
}
// 系统令牌刷新请求对象
const refresh_service = axios.create({
    baseURL: baseURL,  // process.env.VUE_APP_API_BASE_URL,
    timeout: requestTimeOut,
    responseType: 'json',
    validateStatus(status) {
      return status === success
    }
  })
// request interceptor
service.interceptors.request.use(
    config => {
        let _config = config
        try {
            const expireTime = getExpireTime() // Vue.ls.get(EXPIRE_TIME) 
            const access_token = getToken()   //Vue.ls.get(ACCESS_TOKEN)
            const refresh_token = getRefreshToken()

            // if(refresh_token ) refresh_token 为空时，怎么办

            if (expireTime>0) {
                const left = expireTime - new Date().getTime()
                console.log("离令牌失效还有: "+left/1000/60+" 分钟")
                // console.log("refresk_token is:"+refresh_token)
                if (left < checkRegion && refresh_token) 
                {
                  // _config = 
                  queryRefreshToken(_config, refresh_token)
                }// else 
                { //更新完成后，继续执行
                    if (access_token) {
                        // 让每个请求携带自oauth2请求返回来后存储在本地的token 请根据实际情况自行修改
                        // 'bearer ' + 
                        // _config.headers['Authorization'] =  'bearer'+ getToken() // authorizationValue //Vue.ls.get(ACCESS_TOKEN)  //
                        //_config.headers['token'] = access_token
                        _config.headers['Authorization'] = 'bearer' + access_token
                    }
              }
            }
        } catch (e) {
            console.error(e)
        }
        return _config
    },err
)  



// response interceptor

service.interceptors.response.use((response) => {
    return response.data
  }, err)



const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = getToken() //Vue.ls.get(ACCESS_TOKEN)  //
    if (error.response.status === 403) {
      alert({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
        alert({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

const request = {

    refresh(url, params) {
      params['grant_type'] = 'refresh_token'
      return refresh_service.post(url, params, {
        transformRequest: [(params) => {
          return tansParams(params)
        }],
        headers: {
          'Authorization': authorizationValue
        }
      })
    },

    login(url, params) {
      // params['grant_type'] = 'password'
      /*
      return axios({
        url: url,
        method: 'post',
        data: params,
        headers: {
          'Authorization': authorizationValue
        }
      })*/
      
      return service.post(url, params, {
        transformRequest: [(params) => {
          return tansParams(params)
        }],
        headers: {
          'Authorization': authorizationValue
        }
      })
    },

    loginByClient(url){
      var params ={
        grant_type : 'client_credentials',
        scope: 'all'
      }
      return service.post(url, params, {
        transformRequest: [(params) => {
          return tansParams(params)
        }],
        headers: {
          'Authorization': authorizationValue
        }
      })      
    },
    /*    
    refresh(url, params) {
      params['grant_type'] = 'refresh_token'
      return refresh_service.post(url, params, {

        headers: {
          'Authorization': authorizationValue
        }
      })
    },
    login(url, params) {
      params['grant_type'] = 'password'
      console.dir(params)
      return service.post(url, params, {
        headers: {
          'Authorization': authorizationValue
        }
      })
    },*/
    post(url, params) {
      console.dir(params)
      if(params==null)
        return service.post(url,{
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            //'Content-Type': 'application/json;charset=UTF-8'
          }
        });
      else{
        return service.post(url, params, {
          transformRequest: [(params) => {
            return tansParams(params)
          }],
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
      }
    },
    put(url, params) {
      return service.put(url, params, {
        transformRequest: [(params) => {
          return tansParams(params)
        }],
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    },
    get(url, params) {
      let _params
      if (Object.is(params, undefined)) {
        _params = ''
      } else {
        _params = '?'
        for (const key in params) {
          // eslint-disable-next-line no-prototype-builtins
          if (params.hasOwnProperty(key) && params[key] !== null) {
            _params += `${key}=${params[key]}&`
          }
        }
      }
      return service.get(`${url}${_params}`)
    },
    delete(url, params) {
      let _params
      if (Object.is(params, undefined)) {
        _params = ''
      } else {
        _params = '?'
        for (const key in params) {
          // eslint-disable-next-line no-prototype-builtins
          if (params.hasOwnProperty(key) && params[key] !== null) {
            _params += `${key}=${params[key]}&`
          }
        }
      }
      return service.delete(`${url}${_params}`)
    },
    download(url, params, filename) {
      //NProgress.start()
      return service.post(url, params, {
        transformRequest: [(params) => {
          return tansParams(params)
        }],
        responseType: 'blob'
      }).then((r) => {
        const content = r.data
        const blob = new Blob([content])
        if ('download' in document.createElement('a')) {
          const elink = document.createElement('a')
          elink.download = filename
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, filename)
        }
        //ress.done()
      }).catch((r) => {
        console.error(r)
        //NProgress.done()
        alert({
          message: '下载失败',
          type: 'error',
          duration: messageDuration
        })
      })
    },
    upload(url, params) {
      return service.post(url, params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    }
}

function tansParams(params) {
    let result = ''
    Object.keys(params).forEach((key) => {
      if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
        /*
        if(result!='')
          result += '&'+encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        else 
          result = encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
          */
        result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
        // result += key+ '=' + params[key] + '&'
      }
    })
    result = result.substr(0,result.length-1);
    return result
  }
  
async function queryRefreshToken(config, refreshToken) {
    // console.log('准备刷新令牌')
    
    const result = await request.refresh('auth/oauth/token', {
      refresh_token: refreshToken
    })
    if (result.status === success) {
      // saveData(result.data)
      //console.dir(result.data)
      // 更新ACCESS_TOKEN
      Vue.ls.set(ACCESS_TOKEN, result.data.access_token,1000 * result.expires_in) //令牌有效时间
      Vue.ls.set(REFRESH_TOKEN, result.data.refresh_token,1000 * result.expires_in) //令牌有效时间
      const expire_time = (new Date()).setTime((new Date()).getTime()+ 1000 * result.data.expires_in)
      Vue.ls.set(EXPIRE_TIME,expire_time)
      config.headers['Authorization'] = 'bearer' + getToken()
    
      console.log('更新令牌成功!!!')
    }
    //return config
}
  
//pureAxios 有些地方要用到
export const pureAxios = axios.create({
  baseURL: baseURL, // api base_url
  timeout: 6000 // 请求超时时间
})
pureAxios.interceptors.request.use(config => {
    const token = getToken() //Vue.ls.get(ACCESS_TOKEN)
    if (token) {
        config.headers['Authorization'] = 'bearer ' + token
        //config.headers['token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    return config
  }, err)
 

const installer = {
    vm: {},
    install (Vue) {
        Vue.use(VueAxios, service)
    }
}

export {
    request,
    installer as VueAxios,
    service as axios,
    faxios as faxios
}
