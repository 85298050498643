// import db from '@/utils/localstorage'  
import store from '@/store'

export function getToken() {
  //return Vue.ls.get(ACCESS_TOKEN,'')
  // return db.get(ACCESS_TOKEN, '')

  return store.getters.token

}

// 用户ID 不是用户名
export function getUserId(){
  //return Vue.ls.get('UserID','')
  return store.getters.userId
}
// 商户ID

export function getMerchantId(){
  //DeptID 与MerchantId 一致
  //return Vue.ls.get('DeptID','') 
  return store.getters.deptId
}

export function getRefreshToken() {
  //return Vue.ls.get(REFRESH_TOKEN,'')
  //return db.get(REFRESH_TOKEN, '')
  return store.getters.refreshToken
}

export function getExpireTime() {
  //return Vue.ls.get(EXPIRE_TIME,'')
  //return db.get(EXPIRE_TIME, 0)
  return store.getters.expireTime
}
