import Vue from 'vue'
import { login, getInfo, logout ,loginByClient} from '@/api/login'
import { ACCESS_TOKEN,EXPIRE_TIME,REFRESH_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/utils/util'

const user = {
  state: {
    userId: '1111',
    deptId: '',
    token: '', 
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    buttons: [], // 按钮权限
    info: {},
    expireTime: 0,
    refreshToken:''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      
      state.token = token
      // db.save(ACCESS_TOKEN, token)
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_BUTTONS: (state, buttons) => {
      state.buttons = buttons
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    SET_DEPTID:(state,deptId)=>{
      state.deptId = deptId
    },
    
    SET_EXPIRE_TIME: (state, expire_time) => {
        state.expireTime = expire_time
    },
    SET_REFRESH_TOKEN:(state,refresh_token)=>{
      state.refreshToken = refresh_token
    }

  },
 
  actions: {

    //测试用，以下两种方式等价
    TestAction1(contex,num){
      contex.commit('SET_USERID',num)
    },
    TestAction2({commit},num){
      commit('SET_USERID',num)
    },


    // 以下3个方法通过src/permission.js的router.beforeEac的连接起来
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
            const result = response
            const expire_time = (new Date()).setTime((new Date()).getTime()+ 1000 * result.expires_in)
            //Vue.ls.set(ACCESS_TOKEN, result.access_token,1000 * result.expires_in) //令牌有效时间
            //Vue.ls.set(REFRESH_TOKEN, result.refresh_token,1000 * result.expires_in) //令牌有效时间
            commit('SET_TOKEN', result.access_token)
            commit('SET_EXPIRE_TIME', expire_time)
            commit('SET_REFRESH_TOKEN',result.refresh_token,1000 * result.expires_in)
            //Vue.ls.set(EXPIRE_TIME,expire_time)
            
            resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    LoginByClient({commit}){
      return new Promise((resolve,reject)=>{
        loginByClient().then(response=>{
          const result = response
          console.dir(result)
          const expire_time = (new Date()).setTime((new Date()).getTime()+ 1000 * result.expires_in)
        
          commit('SET_TOKEN', result.access_token)
          commit('SET_EXPIRE_TIME', expire_time)
          commit('SET_REFRESH_TOKEN',result.refresh_token,1000 * result.expires_in)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const result = response.data
          // console.dir(response.data)
          if (result.roleIds) {
            commit('SET_ROLES', result.roleIds)
            commit('SET_BUTTONS', result.buttons)
            commit('SET_USERID', result.userId)
            commit('SET_INFO', result)
            commit('SET_DEPTID',result.deptId)
            //Vue.ls.set('UserID',result.userId)
            //Vue.ls.set('DeptID',result.deptId)
          } else {
            reject(new Error('getInfo: roles must be a non-null array !'))
          }
          commit('SET_NAME', { name: result.userName, welcome: welcome() })
          commit('SET_AVATAR', result.avatar || '/avatar2.jpg')
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_INFO', {})

         // Vue.ls.remove(ACCESS_TOKEN)
         // Vue.ls.remove(EXPIRE_TIME)
          //Vue.ls.remove(REFRESH_TOKEN)
        })
      })
    }

  }
}

export default user
