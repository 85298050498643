import Vue from 'vue'
import router from '@/router'
import { getToken } from '@/utils/auth'
import {loginByClient} from '@/api/login'
import store from '@/store'


router.beforeEach((to, from, next) => {
    console.dir("拟跳转 to:"+to.name+"+++access token is: "+store.getters.token)

    //if (Vue.ls.get(ACCESS_TOKEN)) 
    if(getToken()){
        next()
      } else {
        console.log("用客户端方式去获取token")
        
        store.dispatch('LoginByClient').then(() => {
          next()
        }).catch(() => {
          alert({
            message: '错误',
            description: '请求用户信息失败，请重试'
          })/*
          store.dispatch('Logout').then(() => {
            next({ path: '/user/login', query: { redirect: to.fullPath } })
          })*/
        })
      }


})
