import api from './index'
import { axios, pureAxios, request } from '@/utils/request'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  return request.login('/auth/oauth/token',parameter)
  // return request.login('/api/auth/oauth/login',parameter)
}
export function loginByClient () {
  return request.loginByClient('/auth/oauth/token')
}

export function getSmsCaptcha (parameter) {
  return axios({
    url: api.SendSms,
    method: 'post',
    params: parameter
  })
}

export function getInfo () {
  //return request.get('/system/user/info');
  return request.get('/auth/user/userDetail');
  /*
  return axios({
    url: '/system/user/info',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })*/
}

export async function logout (token) {
  
  //return request.post('/auth/user/exit');
  
  return axios({
    url: '/auth/user/logout',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'token': token
    }
  })
}

export function imgcode () {
  return pureAxios({
    url: '/code',
    method: 'get',
    responseType: 'blob'
  })
}


/**
 * get user 2step code open?
 * @param parameter {*}
 */
export function get2step (parameter) {
  return axios({
    url: api.twoStepCode,
    method: 'post',
    data: parameter
  })
}

