
const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  nickname: state => state.user.name,
  welcome: state => state.user.welcome,
  roles: state => state.user.roles,
  userInfo: state => state.user.info,
  // 从后台获取的用户 按钮权限
  buttons: state => state.user.buttons,
  userId: state => state.user.userId,
  expireTime: state =>state.user.expireTime,
  deptId: state => state.user.deptId,
  refreshToken: state =>state.user.refreshToken

  //addRouters: state => state.permission.addRouters

}
export default getters
