import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view';

import '@/api/permission' // permission control

import Antd from 'ant-design-vue';/* 全局引入ant */
import 'ant-design-vue/dist/antd.css'; /* 全局引入ant样式文件*/

// 引入全局css
import './assets/scss/style.scss';

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

Vue.use(dataV);
Vue.use(Antd)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
