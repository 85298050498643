export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

export function millsToTime (mills) {
  if (!mills) {
    return ''
  }
  const s = mills / 1000
  if (s < 60) {
    return s.toFixed(0) + ' 秒'
  }
  const m = s / 60
  if (m < 60) {
    return m.toFixed(0) + ' 分钟'
  }
  const h = m / 60
  if (h < 24) {
    return h.toFixed(0) + ' 小时'
  }
  const d = h / 24
  if (d < 30) {
    return d.toFixed(0) + ' 天'
  }
  const month = d / 30
  if (month < 12) {
    return month.toFixed(0) + ' 个月'
  }
  const year = month / 12
  return year.toFixed(0) + ' 年'
};

// 32位uuid
export function guid() {
  function S4() {
     return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  }
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}
/*
	指定长度和基数

*/
export function uuid2(len, radix) {
	var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
	var uuid = [],
		i;
	radix = radix || chars.length;

	if (len) {
		// Compact form
		for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
	} else {
		// rfc4122, version 4 form
		var r;

		// rfc4122 requires these characters
		uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
		uuid[14] = '4';

		// Fill in random data.  At i==19 set the high bits of clock sequence as
		// per rfc4122, sec. 4.1.5
		for (i = 0; i < 36; i++) {
			if (!uuid[i]) {
				r = 0 | Math.random() * 16;
				uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
			}
		}
	}

	return uuid.join('');
};

//最近一周 getDay(-7)    返回的是距离当前日期的一周后的时间 
//一月 getDay(-30)//一年 getDay(-365)
export function getDay(day){
  　　var today = new Date();
  　　var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
  　　today.setTime(targetday_milliseconds); //注意，这行是关键代码
  　　var tYear = today.getFullYear();
  　　var tMonth = today.getMonth();
  　　var tDate = today.getDate();
  　　tMonth = doHandleMonth(tMonth + 1);
  　　tDate = doHandleMonth(tDate);
  　　return tYear+"-"+tMonth+"-"+tDate;
  
  };
  function doHandleMonth(month){
  　　var m = month;
  　　if(month.toString().length == 1){
  　　　　m = "0" + month;
  　　}
  　　return m;  
  }
export function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}  