import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/index',
    name: 'index',
    component: () => import('../views/AiotView.vue')
    //component: () => import('../views/GDView.vue')
  }
  ,
  {
    path: '/test/:route_id',
    name: 'test',
    //component: () => import('../views/AiotView.vue')
    component: () => import('../views/TestVue.vue')

  }
  ,
  
  {
    path: '/gdws',
    name: 'gdws',
    //component: () => import('../views/AiotView.vue')
    component: () => import('../views/GDViewWS.vue')
  }
  ,
  {
    path: '/left1',
    name: 'left1',
    component: () => import('@/components/echart/left/left1/index.vue')
  },
  
  {
    path: '/demo2',
    name: 'demo2',
    component: () => import('@/components/echart/cakeLinkage/index.vue')
  },
  {
    path: '/demo3',
    name: 'demo3',
    component: () => import('@/components/echart/circleRunway/index.vue')
  },
  {
    path: '/linec',
    name: 'linec',
    component: () => import('@/components/echart/line/index.vue')
  },
  {
    path: '/line',
    name: 'line',
    component: () => import('@/views/HistorySoilView.vue')
  }
  ,
  {
    path: '/cake',
    name: 'cake',
    component: () => import('@/components/echart/cakeLinkage/index.vue')
  }
  ,
  {
    path: '/dynamicList',
    name: 'dynamicList',
    component: () => import('@/components/other/dynamicList/index.vue')
  },
  {
    path: '/aiot',
    name: 'aiot',
    component: () => import('@/views/AiotView.vue')
  },
  {
    path: '/vuex',
    name: 'vuex',
    component: () => import('@/views/VuexView.vue')
  },
 
  {
    path: '/groupSoil',
    name: 'groupSoil',
    component: () => import('@/views/GroupSoilView.vue')
  },
  {
    path: '/tvue',
    name: 'tvue',
    component: () => import('@/views/HistoryBeamView.vue')
  }
]

screenLeft
const router = new VueRouter({
  mode:'history',
  routes
  
})


export default router
